<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>
<script setup>
import { computed, onMounted, onUnmounted } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { ElMessage } from 'element-plus'
import inFo from './utils/inFo.js'

// 语言切换
const locale = computed(() => (window.SITE_CONFIG.defaultLanguage === 'CN' ? zhCn : en))
// 生命周期 渲染结束
onMounted(() => {
  // 监听网络
  window.addEventListener('online', updateNetworkStatus)
  window.addEventListener('offline', updateNetworkStatus)
})
onUnmounted(() => {
  window.removeEventListener('online', updateNetworkStatus)
  window.removeEventListener('offline', updateNetworkStatus)
})
const updateNetworkStatus = (event) => {
  if (!navigator.onLine) {
    inFo.emit('loadingDownload', false) // 如果正在下载，把正在下载的loading关掉
    ElMessage({ type: 'error', message: '网络已断开连接，请检查本地网络设置!' })
  }
}
</script>
<style lang="scss">
#app {
  background: #f3f4f7;
  font-family: "Microsoft YaHei",serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
