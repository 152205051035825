import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 element 组件和样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入 element 图标 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入 default-passive-events 作用：在触摸事件上默认使用passive选项，避免阻止滚动
import 'default-passive-events'
// 引入工具中的函数
import { letGo, onLoad, offLoad, onLogin, offLogin } from './utils/tool.js'
// 引入 i18n 多语言
import i18n from './language/Language'
// 引入自定义样式
import './assets/css/base.scss'
// 引入用户信息实体类
import UserInfoGlobal from './utils/UserInfoGlobal'
// 引入 cookie 方便管理token等信息
import Cookies from 'js-cookie'
// 引入 rem 重置rem适配基数
import './utils/rem.js'
// 引入 bootstrap 组件和样式
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// 使用 domain 解决跨域问题
Cookies.domain = window.SITE_CONFIG.domain
const app = createApp(App)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router)
app.use(store)
app.use(i18n)
app.provide('$userInfoGlobal', UserInfoGlobal)
app.mount('#app')
// 暴露函数给pc端调用
window.letGo = letGo
window.offLogin = offLogin
window.onLogin = onLogin
window.onLoad = onLoad
window.offLoad = offLoad
